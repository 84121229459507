import { H2, HorizontalGroup, Input } from "@saysom/shared"
import React, { FunctionComponent, useState } from "react"
import { Button, CustomImage, Form, Label, TextAreaInput } from "../../styles/"
import { ContactContent } from "./newsletter"

const Message: FunctionComponent<ContactContent> = ({ cancel }) => {
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [message, setMessage] = useState("")

	const submit = (e) => {
		e.preventDefault()

		const nameParts = name.split(" ")

		// todo: refactor
		fetch(
			"https://api.hsforms.com/submissions/v3/integration/submit/8923531/7d9477f5-6fd5-487e-b9ff-b4897b080459",
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					submittedAt: new Date().getTime(),
					fields: [
						{
							name: "email",
							value: email,
						},
						{
							name: "firstname",
							value: nameParts[0],
						},
						{
							name: "lastname",
							value: nameParts.length > 1 ? nameParts[1] : "",
						},
						{ name: "message", value: message },
					],
				}),
			}
		)
			.then((r) => r.json())
			.then((v) => {
				if (v.status !== undefined) {
					alert("Error: Data not valid. Please check your input")
				} else {
					alert("Thank you for your message!")

					cancel()
				}
			})
	}

	return (
		<>
			<HorizontalGroup justifyContent="space-between" alignItems="center">
				<H2>Contact us</H2>
				<Button onClick={() => cancel()}>
					<CustomImage src="/assets/images/cancel.svg" />
				</Button>
			</HorizontalGroup>
			<p style={{ margin: "10px 0 20px 0" }}>
				Have a question? Want something special? Just want to chat? Don&apos;t
				hesitate, just contact us!
			</p>
			<Form onSubmit={submit}>
				<Label style={{ marginBottom: "20px" }}>
					Name
					<Input
						placeholder="Name"
						type="text"
						value={name}
						onChange={(value) => setName(value)}
					/>
				</Label>
				<Label style={{ marginBottom: "20px" }}>
					Email
					<Input
						placeholder="Email"
						type="email"
						value={email}
						onChange={(value) => setEmail(value)}
					/>
				</Label>
				<Label style={{ marginBottom: "20px" }}>
					Message
					<TextAreaInput
						placeholder="Message"
						value={message}
						onChange={({ target: { value } }) => setMessage(value)}
					/>
				</Label>

				<Button
					disabled={
						name.length === 0 ||
						email.length === 0 ||
						message.length === 0 ||
						!email.includes("@")
					}
					style={{ marginTop: "10px" }}
					type="submit"
				>
					Send message
				</Button>
			</Form>
		</>
	)
}

export default Message
