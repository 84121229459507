import { create } from "zustand"

class CustomError {
	title: string
	message: string

	constructor(title?: string, message?: string) {
		this.title = title ? title : "Error"
		this.message = message ? message : "Unknown error"
	}
}

interface ErrorStore extends Record<string, unknown> {
	error?: CustomError

	update: (error: Error) => void
	clear: () => void
}

export const useErrorStore = create<ErrorStore>((set, _get) => {
	return {
		error: undefined,

		update(error: Error) {
			set({ error: new CustomError(error.name, error.message) })
		},

		clear() {
			set({ error: undefined })
		},
	}
})

// contact button überall möglich?
