import Head from "next/head"
import Script from "next/script"
import { Cookies, CookiesProvider } from "react-cookie"
import { CSSTransition } from "react-transition-group"
import Alert from "../components/alert/alert"
import Contact from "../components/contact/contact"
import { useErrorStore } from "../stores/errorStore"
import "../styles/globals.css"

const MyApp = ({ Component, pageProps, cookies }) => {
	const error = useErrorStore((state) => state.error)

	const cookiesObject = new Cookies(cookies)

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
				/>
				<meta name="description" content="SaySom.app" />

				<title>SaySom</title>

				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2cd7f4" />
				<meta name="msapplication-TileColor" content="#00aba9" />
				<meta name="theme-color" content="#ffffff" />

				<link
					rel="preload"
					href="/assets/fonts/CircularStd-Book.woff2"
					as="font"
					crossOrigin=""
				/>
				<link
					rel="preload"
					href="/assets/fonts/CircularStd-Medium.woff2"
					as="font"
					crossOrigin=""
				/>
				<link
					rel="preload"
					href="/assets/fonts/CircularStd-Bold.woff2"
					as="font"
					crossOrigin=""
				/>
				<link
					rel="preload"
					href="/assets/fonts/CircularStd-Black.woff2"
					as="font"
					crossOrigin=""
				/>
			</Head>

			<CookiesProvider cookies={cookiesObject}>
				<Component {...pageProps} />
			</CookiesProvider>

			<CSSTransition
				in={error !== undefined}
				timeout={300}
				classNames={"alert-background"}
				unmountOnExit
			>
				<Alert error={error} />
			</CSSTransition>

			<Contact />

			{process.env.NODE_ENV === "production" && (
				<>
					<Script id="google-analytics" strategy="afterInteractive">
						{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
									new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
									j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
									'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
								})(window,document,'script','dataLayer','GTM-5TSCDCD');`}
					</Script>

					<noscript
						dangerouslySetInnerHTML={{
							__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5TSCDCD"
									height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
						}}
					/>
				</>
			)}
		</>
	)
}

export default MyApp
