import styled from "styled-components"
import { BorderRadius } from "../../styles"

export const Container = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.2);
`

export const Body = styled.div<{ scale: string }>`
	border-radius: ${BorderRadius};
	box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	background-color: white;
	min-width: 300px;
	max-width: calc(100vw - 40px);
	padding: 20px;
	transition: all 300ms;
	transform: ${(props) => props.scale};
`
