import React, { useEffect, useRef, useState } from "react"
import { config, interpolate, useTransition } from "react-spring"
import { useContactStore } from "../../stores/contactStore"
import { Box, Container, InnerBox } from "./contact_style"
import Message from "./message"
import Newsletter from "./newsletter"

// todo: refactor
const Contact = () => {
	const contactElement = useContactStore((state) => state.contactElement)
	const type = useContactStore((state) => state.type)
	const cancel = useContactStore((state) => state.cancel)
	const [items, setItems] = useState([])
	const contactRef = useRef(undefined)

	useEffect(() => {
		if (contactElement) {
			const viewportOffset = contactElement.getBoundingClientRect()

			setItems([
				{
					top:
						viewportOffset.top -
						window.innerHeight / 2 +
						contactElement.offsetHeight / 2,
					left:
						viewportOffset.left -
						window.innerWidth / 2 +
						contactElement.offsetWidth / 2,
					scaleX: contactElement.offsetWidth / 500,
					scaleY:
						contactElement.offsetHeight / (type === "newsletter" ? 350 : 500),
					width: window.innerWidth <= 500 ? window.innerWidth - 40 : 500,
					height: type === "newsletter" ? 400 : 500,
					buttonWidth: contactElement.offsetWidth,
					buttonHeight: contactElement.offsetHeight,
					borderRadius: contactElement.offsetHeight / 2,
				},
			])

			contactElement.style.opacity = 0
			contactRef.current = contactElement
		} else {
			setItems([])
		}
	}, [contactElement, type])

	const transitions = useTransition(items, (item) => item.key, {
		from: (item) => {
			return {
				backgroundOpacity: 0,
				opacity: 0.5,
				translateX: item.left,
				translateY: item.top,
				width: item.buttonWidth,
				height: item.buttonHeight,
				innerWidth: item.width,
				innerHeight: item.height,
				scaleX: item.scaleX,
				scaleY: item.scaleY,
				borderRadius: item.borderRadius,
			}
		},
		enter: (item) => {
			return {
				backgroundOpacity: 0.8,
				opacity: 1,
				translateX: 0,
				translateY: 0,
				scaleX: 1,
				scaleY: 1,
				width: item.width,
				height: item.height,
				innerWidth: item.width,
				innerHeight: item.height,
				borderRadius: 20,
			}
		},
		leave: (item) => {
			return {
				backgroundOpacity: 0,
				opacity: 0.5,
				translateX: item.left,
				translateY: item.top,
				width: item.buttonWidth,
				height: item.buttonHeight,
				innerWidth: item.width,
				innerHeight: item.height,
				scaleX: item.scaleX,
				scaleY: item.scaleY,
				borderRadius: item.borderRadius,
			}
		},
		onRest: () => {
			if (contactElement === undefined) {
				contactRef.current.style.opacity = 1
			}
		},
		config: (item, state) => {
			return state === "leave" ? { duration: 150 } : config.default
		},
	})

	const scale = (scaleX, scaleY) => `scaleX(${scaleX}) scaleY(${scaleY})`
	const color = (backgroundOpacity) =>
		`rgba(255, 255, 255, ${backgroundOpacity})`

	return (
		<>
			{transitions.map(
				({
					props: {
						translateX,
						translateY,
						backgroundOpacity,
						scaleX,
						scaleY,
						innerWidth,
						innerHeight,
						opacity,
						...rest
					},
					key,
				}) => (
					<Container
						style={{
							background: interpolate([backgroundOpacity], color),
						}}
						key={key}
					>
						<Box
							style={{
								left: translateX,
								top: translateY,
								...rest,
							}}
						>
							<InnerBox
								style={{
									transform: interpolate([scaleX, scaleY], scale),
									width: innerWidth,
									height: innerHeight,
									opacity,
								}}
							>
								{type === "newsletter" ? (
									<Newsletter cancel={cancel} />
								) : (
									<Message cancel={cancel} />
								)}
							</InnerBox>
						</Box>
					</Container>
				)
			)}
		</>
	)
}

export default Contact
