import { Shadows } from "@saysom/shared"
import { animated } from "react-spring"
import styled from "styled-components"

export const Container = styled(animated.div)`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
`

export const Box = styled(animated.div)`
	background-color: white;
	box-shadow: ${Shadows.highlighted};

	display: flex;
	align-items: stretch;
	transform-style: preserve-3d;

	position: relative;
`

export const InnerBox = styled(animated.div)`
	position: absolute;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transform-style: preserve-3d;
	transform-origin: top left;
`
