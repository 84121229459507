import { Colors, Shadows, VerticalGroup } from "@saysom/shared"
import { Property } from "csstype"
import styled from "styled-components"

// Colors
export const BlueGrey = "#808C99"

// Shadow & Border
export const BorderRadius = "8px"

// Gradient
export const Gradient = "linear-gradient(103.87deg, #FA8BFF 0%, #6867FF 100%)"

// General

export const PLarge = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 28px;

	color: ${(props) => (props.color ? props.color : Colors.black)};

	@media (max-width: 500px) {
		font-size: 14px;
	}
`

export const CustomImage = styled.img.attrs({
	draggable: false,
})`
	display: block;
`

export const Button = styled.button<{
	defaultBackground?: boolean
	background?: Property.Background
}>`
	background: #ffffff;
	box-shadow: ${Shadows.normal};
	border-radius: 200px;
	border: 0;
	outline: 0;
	cursor: pointer;

	font-style: normal;
	font-weight: 600;
	font-size: 16px;

	color: #333333;
	transition: all 200ms ease-in-out;

	padding: 8px 16px;

	:hover {
		color: white;
		//transform: scale(1.05);
		background: ${(props) =>
			!props.defaultBackground &&
			(props.background ? props.background : "#ffffff")};
		color: ${(props) =>
			!props.defaultBackground &&
			(props.background ? "#ffffff" : Colors.black)};
	}

	:active {
		transform: scale(0.9);
	}

	:disabled {
		pointer-events: none;
		color: rgb(180, 180, 180);
	}

	@media (max-width: 500px) {
		font-size: 14px;
	}
`

export const BlackButton = styled.button`
	border-radius: 30px;
	box-shadow: ${Shadows.normal};
	border: none;
	padding: 9px 35px 10px 35px;
	color: white;
	font-weight: 700;
	outline: none;
	cursor: pointer;
	font-size: 18px;
	background-color: ${Colors.black};
	transition: transform 200ms ease-in-out;

	:disabled {
		pointer-events: none;
		background: lightgrey;
	}

	:active {
		transform: scale(0.9);
	}
`

export const WhiteButton = styled(BlackButton)`
	background: white !important;
	color: ${Colors.black};

	padding: 11px 30px 12px 30px;
`

export const GradientButton = styled(BlackButton)`
	background: ${Gradient};
	color: white;

	padding: 11px 30px 12px 30px;
`

export const ButtonIcon = styled.div<{
	width: Property.Width
	height: Property.Height
	src: string
}>`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	bottom: 1px;
	position: relative;
	background-color: ${(props) => (props.color ? props.color : Colors.black)};
	mask: url(${(props) => props.src}) no-repeat center;
`

// Forms

export const Form = styled.form`
	display: flex;
	flex-direction: column;
`

export const Label = styled.label`
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	font-size: 14px;
`

export const TextAreaInput = styled.textarea`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	flex: 1;
	margin-top: 5px;
	padding: 15px 10px;
	border-radius: ${BorderRadius};
	border: 1px solid ${Colors};
	outline: none;

	background: none;

	border: 1px solid #efefef;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);

	:focus {
		border: 1px solid #c4c4c4;
	}

	::placeholder {
		color: #808c99;
	}
`

// Box

export const CenterContainer = styled.div<{ width: string | undefined }>`
	align-self: center;
	justify-self: center;
	width: ${(props) => (props.width ? props.width : "inherit")};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (max-width: ${(props) => props.width}) {
		max-width: 100%;
		padding: 0 20px;
		/* min-width: 100%;
		max-width: 100%;
		width: 100%; */
	}
`

export const Container = styled(VerticalGroup)`
	align-self: stretch;

	border-radius: 30px;
	padding: 50px 40px;

	@media (max-width: 768px) {
		padding: 30px 24px;
	}
`

export const SmallContainer = styled.div<{
	backgroundColor?: string
	border?: string
	justify?: string
	align?: string
	shadow?: string
	gradient?: boolean
	background?: string
	mt?: string
	mtmd?: string
}>`
	display: flex;
	flex-direction: column;
	align-self: stretch;
	background-color: ${(props) => props.backgroundColor};
	background: ${(props) => (props.gradient ? Colors.flowy : props.background)};
	box-sizing: border-box;
	border-radius: 20px;
	border: ${(props) => props.border};
	padding: 30px 20px;
	flex: 1;
	justify-content: ${(props) => props.justify};
	align-items: ${(props) => props.align};
	box-shadow: ${(props) => props.shadow};
	margin-top: ${(props) => props.mt};

	@media (max-width: 768px) {
		margin-top: ${(props) => props.mtmd};
	}
`

export const Select = styled.select`
	appearance: none;
	width: 100%;
	border-radius: 5px;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-size: 14px;

	flex: 1;
	margin-top: 5px;
	padding: 15px 10px;
	border-radius: 8px;
	border: 1px solid #333333;
	outline: none;

	border: 1px solid #efefef;

	:focus {
		border: 1px solid #c4c4c4;
	}

	::placeholder {
		color: #808c99;
	}

	padding: 14px 24px;
`

export const Option = styled.option`
	padding: 14px 24px;
`
