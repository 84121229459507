import { H1 } from "@saysom/shared"
import React, { useCallback, useEffect, useState } from "react"
import { useErrorStore } from "../../stores/errorStore"
import { Button } from "../../styles"
import { Body, Container } from "./alert_style"

// todo: animate with react spring (later…)
const Alert = ({ error }) => {
	const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")
	const [scale, setScale] = useState("scale(0.9)")
	const [custom, setCustom] = useState(null)
	const clear = useErrorStore((state) => state.clear)

	const close = useCallback(() => {
		setScale("scale(0.9)")

		clear()
	}, [clear])

	useEffect(() => {
		const onKeyPress = (e) => {
			const { key } = e
			if (key === "Enter" || key === "Escape") {
				e.preventDefault()

				close()
			}
		}

		window.addEventListener("keydown", onKeyPress)

		return () => {
			window.removeEventListener("keydown", onKeyPress)
		}
	}, [close])

	useEffect(() => {
		if (error && error.message && error.message !== description) {
			setTitle(error.title)
			setDescription(error.message)

			if (scale === "scale(0.9)") {
				window.requestAnimationFrame(function () {
					setScale("scale(1.0)")
				})
			}

			if (error.custom) {
				setCustom(error.custom)
			}
		}
	}, [error, description, scale])

	return (
		<Container>
			<Body scale={scale}>
				<H1
					style={{
						marginBottom: "10px",
					}}
				>
					{title ? title : "Error"}
				</H1>
				<p
					style={{
						marginBottom: "10px",
					}}
				>
					{description}
				</p>

				{custom}

				<Button
					style={{
						width: "120px",
						marginTop: "10px",
					}}
					onClick={close}
				>
					Close
				</Button>
			</Body>
		</Container>
	)
}

export default Alert
