import { create } from "zustand"

export enum ContactType {
	Message = "message",
	Newsletter = "newsletter",
}

interface ContactStore extends Record<string, unknown> {
	contactElement: HTMLElement | undefined
	type: ContactType

	onClick: (contactElement: HTMLElement, type: ContactType) => void
	cancel: () => void
}

export const useContactStore = create<ContactStore>((set, _get) => {
	return {
		contactElement: undefined,
		type: ContactType.Message,

		onClick(contactElement, type) {
			set({ contactElement, type })
		},
		cancel() {
			set({ contactElement: undefined })
		},
	}
})
